

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  
}



.App-header {
  background: url('hero.svg') no-repeat;
  background-color: #BAE3F5;
  background-position:top;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.About-header{
  background-color: #BAE3F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.About-header h3{
  font-size: 15rem;
}


.Portfolio-header{
  background-color: #BAE3F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.Portfolio-header h3{
font-size: 15rem;
}

.Testimonial-header{
  background-color: #BAE3F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}
.Testimonial-header h3{
font-size: 15rem;
}




.Blog-header{
  background-color: #BAE3F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.Blog-header h3{
font-size: 20rem;
}


.Contact-header{
  background-color: #BAE3F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.Contact-header h3{
 font-size: 15rem;
}




.App-header h3{
background: blue;
opacity: 0.4;

}

.card{
border: 1px solid rgb(40, 44, 52);
}
.card .card-header{
background-color: rgb(40, 44, 52);
color:#fff;
}

form input, form textarea{
border: 1px solid rgb(40, 44, 52) !important; 

}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/*loader*/

.loader-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

.sidebar{

animation: side 1s;

}


@keyframes side{
from{ margin-left: -170px; }

to{margin-left: 0px;}

}


/*============ Portfolio Details ================*/


.portfolio .portfolio-detail{
  position:absolute;
  top:0;
  bottom:0;
  background: #fff;
  visibility:hidden;
}

.card:hover .portfolio-detail{
  visibility: visible;
  animation: cardanim 0.5s;
  cursor:pointer;
  width:100%;
}


@keyframes cardanim{
from{ height:0%; }
to{ height:100%; }

}


/******** quote form ************/

.quote-form{


}

/********** Testimonials ************/

.testimonial .slide{
scrollbar-width: none;

-ms-overflow-style: none;

}

.testimonial .slide::-webkit-scrollbar {
  display: none;
}


/*************************/

@media screen and (max-width: 900px){
.Contact-header h3, .About-header h3, .Blog-header h3, .Portfolio-header h3, .Testimonial-header h3{
 font-size: 3rem;
}

.App-header{
background-size: 300px 700px;
}

}










